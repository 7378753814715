import { Container } from "typescript-ioc";
import { ReadyHelper } from "./ReadyHelper";

declare global {
    interface Window {
        /**
         * Global ReadyHelper used for proper timing of modules' initializations.
         */
        readyHelper?: ReadyHelper;
    }
}

window.readyHelper = Container.get(ReadyHelper);
